// src/components/LandingPage.js
import React from 'react';

const LandingPage = ({ onStart }) => {
  // Styles for centering and button
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '400vh',
    textAlign: 'center',
    backgroundColor: '#ae7ad9',
    backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/landing-background.jpg'})`,
    backgroundSize: 'cover',
    color: '#333'
  };

  const buttonStyle = {
    marginTop: '40px',
    padding: '20px 40px',
    backgroundColor: 'blue',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1.0rem', // Increased font size for larger button text
    fontWeight: 'bold', // Added bold weight for emphasis
  };

  const footerStyle = {
    textAlign: 'center',
    padding: '10px',
    backgroundColor: '#ae7ad9',
    color: '#333',
    position: 'fixed',
    width: '100%',
    bottom: 0,
    fontSize: '0.9rem',
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ fontWeight: 800 }}>LOOP</h1> {/* Apply semi-bold to the LOOP heading */}
      <p>Start Tracking Carbon Emission!</p>
      <button style={buttonStyle} onClick={onStart}>Start Tracking</button>
      <footer style={footerStyle}>
        <p>All rights @ LOOP Matters 2024</p>
      </footer>
    </div>
  );
};

export default LandingPage;  // Ensure default export