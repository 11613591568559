import React from 'react';

const FlightOptions = ({ setFlying, onNext }) => {
  const flyingOptions = [
    { value: 1, text: 'I never fly privately.' },
    { value: 2, text: 'I fly privately once or twice a year within Europe.' },
    { value: 3, text: 'I fly long haul privately about once a year (or about four times within Europe).' },
    { value: 4, text: 'I fly long haul privately two or three times a year (or regularly).' },
  ];

  // Styles for positioning content higher on the page
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start', // Aligns content to the top
    height: '100vh', // Full viewport height
    paddingTop: '50px', // Add padding to push content down a bit if needed
    textAlign: 'center',
  };

  const buttonStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: 'blue',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1.0rem', // Increased font size for larger button text
    fontWeight: 'bold', // Added bold weight for emphasis
  };

  return (
    <div style={containerStyle}>
      <h2>Flight Options</h2>
      {flyingOptions.map((option) => (
        <div key={option.value} style={{ marginBottom: '10px' }}>
          <input
            type="radio"
            id={`flight-${option.value}`}
            name="flying"
            value={option.value}
            onChange={() => setFlying(option.value)}
          />
          <label htmlFor={`flight-${option.value}`} style={{ marginLeft: '8px' }}>
            {option.text}
          </label>
        </div>
      ))}
      <button style={buttonStyle} onClick={onNext}>
        Next
      </button>
    </div>
  );
};

export default FlightOptions;



