import React from 'react';

const ShoppingOptions = ({ setShopping, onNext }) => {
  const shoppingOptions = [
    { value: 1, text: 'I rarely shop for non-essential items.' },
    { value: 2, text: 'I occasionally shop for clothes or gadgets.' },
    { value: 3, text: 'I frequently buy new clothes, gadgets, and more.' },
    { value: 4, text: 'I regularly shop for luxury items.' }
  ];

  // Reuse the same styles from FoodOptions
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    paddingTop: '50px',
    textAlign: 'center',
  };

  const buttonStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: 'blue',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1.0rem',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h2>Shopping Options</h2>
      {shoppingOptions.map((option) => (
        <div key={option.value} style={{ marginBottom: '10px' }}>
          <input
            type="radio"
            id={`shopping-${option.value}`}
            name="shopping"
            value={option.value}
            onChange={() => setShopping(option.value)}
          />
          <label htmlFor={`shopping-${option.value}`} style={{ marginLeft: '8px' }}>
            {option.text}
          </label>
        </div>
      ))}
      <button style={buttonStyle} onClick={onNext}>
        Next
      </button>
    </div>
  );
};

export default ShoppingOptions;
