// src/App.js
import React, { useState } from 'react'; // Import useState, useState hook from React, it is used to manage state in functional components, in this case it is used to manage the state of the step variable, flight and food varaiables.
import FlightOptions from './FlightOptions';// Import FlightOptions
import FoodOptions from './FoodOptions';
import ShoppingOptions from './ShoppingOptions';
import Results from './Results';
import LandingPage from './LandingPage';
import Background from './Background'; // Import Background


const App = () => {
  const [flying, setFlying] = useState(null);
  const [food, setFood] = useState(null);
  const [shopping, setShopping] = useState(null);
;
  const [step, setStep] = useState(0);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%', // Ensures full width usage
    maxWidth: '80%', // Limits maximum width for better readability
    textAlign: 'center',
    padding: '40px', // Increase padding for more breathing space
    fontSize: '1.5rem', // Increase font size for better visibility
    lineHeight: '1.6', // Adjust line height for better readability
  };

  return (
    <div style={containerStyle}>
      <Background /> {/* Include the Background component */}
      {step === 0 && <LandingPage onStart={handleNext} />}
      {step === 1 && <FlightOptions setFlying={setFlying} onNext={handleNext} />}
      {step === 2 && <FoodOptions setFood={setFood} onNext={handleNext} />}
      {step === 3 && <ShoppingOptions setShopping={setShopping} onNext={handleNext} />} {/* New shopping step */}
    {step === 4 && <Results flying={flying} food={food} shopping={shopping} />} {/* Updated to include shopping */}
  </div>
);
};

export default App;




