import React from 'react';

const Results = ({ flying, food, shopping }) => {
  const calculateFlyingFootprint = (option) => {
    switch (option) {
      case 1: return 0;
      case 2: return 1;
      case 3: return 2;
      case 4: return 3;
      default: return 0;
    }
  };

  const calculateFoodFootprint = (option) => {
    switch (option) {
      case 1: return 0.5;
      case 2: return 1;
      case 3: return 2;
      case 4: return 3;
      default: return 0;
    }
  };

  const calculateShoppingFootprint = (option) => {
    switch (option) {
      case 1: return 0.2;
      case 2: return 1;
      case 3: return 2;
      case 4: return 3;
      default: return 0;
    }
  };

  const flyingFootprint = calculateFlyingFootprint(flying);
  const foodFootprint = calculateFoodFootprint(food);
  const shoppingFootprint = calculateShoppingFootprint(shopping);
  const totalFootprint = flyingFootprint + foodFootprint + shoppingFootprint;

  return (
    <div>
      <h2>Results</h2>
      <p>Flying: {flyingFootprint} kg CO2</p>
      <p>Food: {foodFootprint} kg CO2</p>
      <p>Shopping: {shoppingFootprint} kg CO2</p>
      <p>Total: {totalFootprint} kg CO2</p>
    </div>
  );
};

export default Results;

