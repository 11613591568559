// src/components/Background.js
import React from 'react';
import './Background.css';

const Background = () => {
  return (
    <div className="background">
      <div className="background-element tree"></div>
      <div className="background-element food"></div>
      <div className="background-element flight"></div>
    </div>
  );
};

export default Background;


